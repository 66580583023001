import React from 'react';

import BaseLayout from '../components/layouts/BaseLayout';
import Stage from '../components/layouts/Stage';
import StageIntro from '../components/elements/StageIntro';
import { Section } from '../components/layouts/Containers';
import { Link } from '../components/elements/ButtonsAndLinks';
import { Headline, P } from '../components/elements/Typography';

const ImprintPage = () => (
    <BaseLayout title="Impressum" description="Impressum und rechtliche Informationen">
        <Stage>
            <StageIntro headline={{ text: 'Impressum', smaller: true }} />
        </Stage>
        <Section container="s">
            <P>
                <strong>Staatlichen Toto-Lotto GmbH Baden-Württemberg</strong>
                <br />
                Nordbahnhofstraße 201<br />
                70191 Stuttgart<br />
            </P>
            <P>
                Sie erreichen unseren Kundenservice unter:<br />
                Telefon: 0711 81000-444<br />
                Telefax: 0711 81000-218<br />
                <Link to="mailto:a3VuZGVuc2VydmljZUBsb3R0by1idy5kZQ==" />
                <br />
                Montag bis Samstag 8:00 - 19:00 Uhr<br />
            </P>
            <P gap="xxl">
                Handelsregister Amtsgericht Stuttgart HRB 917<br />
                Umsatzsteueridentifikationsnummer DE 147 794 057<br />
            </P>
            <Headline level="h3">Aufsichtsratsvorsitzende</Headline>
            <P gap="xxl">Ministerin Dr. Nicole Hoffmeister-Kraut MdL</P>

            <Headline level="h3">Geschäftsführer</Headline>
            <P gap="xxl">Georg Wacker</P>

            <Headline level="h3">Aufsichtsbehörde</Headline>
            <P>
                Regierungspräsidium Karlsruhe<br />
                Durlacher Allee 100<br />
                76137 Karlsruhe<br />
            </P>
            <P gap="xxl">
                Stammkapital 16 Mio Euro.<br />
                Das Stammkapital ist voll eingezahlt.
            </P>

            <Headline level="h3">Rechtlicher Hinweis</Headline>
            <P>
                Die Bereitstellung sämtlicher Inhalte und Angaben auf den Internetseiten der
                Staatlichen Toto-Lotto GmbH Baden-Württemberg erfolgt ohne Gewähr.
            </P>
            <P gap="xxl">
                Bilder, Texte und Grafiken auf der Website (www.sportjugendfoerderpreis.de) sind
                urheberrechtlich geschützt. Die Verwendung von Teilen des Inhalts bedarf der
                schriftlichen Genehmigung der Staatlichen Toto-Lotto GmbH Baden-Württemberg.
            </P>

            <Headline level="h3">Konzeption, Design und Programmierung</Headline>
            <P gap="xxl">
                G + P Glanzer und Partner Werbeagentur GmbH<br />
                Paracelsusstraße 26<br />
                70599 Stuttgart<br />
                <Link
                    to="https://www.glanzer-und-partner.de"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    www.glanzer-und-partner.de
                </Link>
            </P>

            <Headline level="h3">Online-Streitbeilegung</Headline>
            <P>
                Online-Streitbeilegung gemäß Art. 14 Abs. 1 der Verordnung über
                Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-VO), §§ 36, 4
                Verbraucherstreitbeilegungsgesetz (VSBG):
            </P>
            <P>
                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) zur
                Verfügung, welche Sie unter dem folgenden Link erreichen können:{' '}
                <Link
                    to="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Portal zur Onlinestreitbeilegung
                </Link>
            </P>
            <P>
                Für Streitbeilegungen nach dem VSBG ist die Allgemeine Schlichtungsstelle des
                Zentrums für Schlichtung e.V., Straßburger Straße 8, 77694 Kehl am Rhein,{' '}
                <Link
                    to="http://www.verbraucher-schlichter.de"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    www.verbraucher-schlichter.de
                </Link>{' '}
                zuständig.
            </P>
            <P>
                Die Staatliche Toto-Lotto GmbH Baden-Württemberg nimmt derzeit nicht am
                Streitbeilegungsverfahren teil.
            </P>
        </Section>
    </BaseLayout>
);

export default ImprintPage;
